import Footer from "../component/Footer";
import Header from "../component/Header";

import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";


import Form from 'react-bootstrap/Form';

const schema = yup.object().shape({
    name: yup.string().required("Enter Name"),
    course: yup.string().required("Select Course"),
    phone: yup.string().required("Enter Mobile Number"),
    message: yup.string().required("Enter Message"),

});

const EnquiryForm = () => {

    const { register, reset, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [message, setMessage] = useState('');
    const [list, setList] = useState();
    const [year, setYear] = useState();

    const saveEnquiry = async (data) => {
        let sr = {

            "course": data.course,
            "phone": data.phone,
            "name": data.name,
            "message": data.message
        }
        await fetch(setting.api + "api/saveEnquiry", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                setMessage("Your Request submitted successfully.")
                reset({
                    // acd_year: '',
                    course: '',
                    name: '',
                    phone: '',
                    message: '',
                });

            });
    }

    const getAllCourse = async (data) => {

        await fetch(setting.api + "api/getAllCourse")
            .then(response => response.json())
            .then(u => {
                setList(u.data);
                console.log(u.data);

            });
    }
    // const getAcademicYear = async () => {
    //     await fetch(setting.api + '/api/getAcademicYear', {
    //         method: "GET",
    //         mode: "cors",
    //     })
    //         .then(response => response.json())
    //         .then(dd => {
    //             setYear(dd);
    //         }
    //         );
    // }

    useEffect(() => {
        getAllCourse();
        // getAcademicYear();
    }, []);

    return (
        <>
            <Header />

            <section className="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Enquiry Form</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>

                            <li> Enquiry Form </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="feedback-title">Enquiry Form</h3>
                        <p className="message">{message}</p>
                        <br />
                        <Form onSubmit={handleSubmit(saveEnquiry)}>



                            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                                <Form.Control type="email" placeholder="Enter Email" />
                                {errors.course && <p>{errors.course.message}</p>}
                            </Form.Group> */}

                            {/* <Form.Group className="mb-3 ">
                                <Form.Select aria-label="Academic Year" {...register("acd_year")}>
                                    <option> Select Academic Year</option>
                                    {year && year.map((a, i) =>
                                        <option value="1">{a.start_year} - {a.end_year}</option>
                                    )}
                                    {errors.acd_year && <p>{errors.acd_year.message}</p>}
                                </Form.Select>
                            </Form.Group> */}

                            <Form.Group className="mb-3 ">
                                <Form.Select aria-label="Select Course" {...register("course")}  >

                                    <option> Select Course</option>
                                    {list && list.map((a, i) =>
                                        <option value={a.id}>{a.course}</option>
                                    )}
                                    {errors.course && <p>{errors.course.message}</p>}

                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Name" className="text"
                                    {...register("name")}
                                />
                                {errors.name && <p>{errors.name.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Mobile No" className="text"
                                    {...register("phone")}
                                />
                                {errors.phone && <p>{errors.phone.message}</p>}
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                    {...register("message")}
                                />
                                {errors.message && <p>{errors.message.message}</p>}
                            </Form.Group>

                            <div className="form-group">
                                <button type="submit" className="d-block custom-button">
                                    <span>Submit Now</span>
                                </button>
                            </div>

                        </Form>



                    </div>
                </div>
            </div >

            <Footer />
        </>
    );
}

export default EnquiryForm;