import Footer from "../component/Footer";
import Header from "../component/Header";
// import React, { useEffect, useState } from "react";
import setting from '../setting.json';
import { Row, Col, Button, Card, CardBody, Form } from "react-bootstrap";



import React, { useEffect, useState } from "react";
// import Header from "../../components/ChooseSection/Navbar/Header";
// import Footer from "../../components/Footer/Footer";
// import "./Faculty.css";
// import setting from "../../setting.json";
// import { Row, Col, Button } from "react-bootstrap";

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { Form, CardBody, Card } from "react-bootstrap";


const schema = yup.object().shape({
  course_id: yup.string().required("Fill value"),
  type: yup.string().required("Fill value"),

});


const Faculty = () => {
  const { register, handleSubmit, formState: { errors }, } = useForm({
    resolver: yupResolver(schema),
  });



  const [coursecList, setCoursecList] = useState();
  const [faculty, setFaculty] = useState('');

  const getAllCourse = async () => {
    await fetch(setting.api + "api/getAllCourse", {
      method: "GET",
      mode: "cors",
    })
      .then(response => response.json())
      .then(u => {
        setCoursecList(u.data);

      });
  }

  const getFacultyRecord = async (data) => {

    let sr = {
      "course_id": data.course_id,
      "type": data.type,

    }
    await fetch(setting.api + "api/getFacultyRecord", {
      body: JSON.stringify(sr),
      method: "post",
      mode: "cors",
      headers: {

        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(u => {
        setFaculty(u.data);

      });
  }

  useEffect(() => {
    getAllCourse();
  }, []);


  return (
    <>
      <Header />
      <section
        class="page-header bg_img"
        style={{
          backgroundImage: "URL(./images/banner2.jpg)",
          height: "30lvh",
        }}
      >
        <div class="container">
          <div class="page-header-content">
            <h1
              class="title text-light"
              style={{
                WebkitTextStrokeColor: "black",
                WebkitTextStrokeWidth: "1px",
              }}
            >
              Faculty
            </h1>
            <ul class="breadcrumb">
              <li>
                <a className="text-light" href="/">
                  Home
                </a>
              </li>
              <li className="text-light"> Faculty</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="teacher-section py-3 bg_img ">

        <div className="cart-checkout-box mb-widget">

        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="section-header">
                <Card>
                  <CardBody>
                    <Form onSubmit={handleSubmit(getFacultyRecord)} >
                      <Row >
                        <Col>
                          <Form.Group>
                            <Form.Select aria-label="Default select example" {...register("course_id")}
                            >
                              <option >Course</option>
                              {
                                coursecList && coursecList.map((a) =>
                                  <option key={a.id} value={a.id}>{a.course}</option>
                                )
                              }
                            </Form.Select>
                            {errors.course_id && <p>{errors.course_id.message}</p>}
                          </Form.Group>
                        </Col >
                        <Col>
                          <Form.Group>
                            <Form.Select aria-label="Default select example"
                              {...register("type")}
                            >
                              <option>Select Type</option>
                              <option value="1">Teaching</option>
                              <option value="2">Non-Teaching</option>

                            </Form.Select>
                            {errors.type && <p>{errors.type.message}</p>}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Button type="submit" variant="secondary">Search</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>

                <h3 class="title">Our Teachers</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-light">

            {
              faculty && faculty.map((f) =>
                <div className="col-lg-3">
                  <img src={f.photo}
                    className="col-12 teacher-img my-3 " alt="" />
                  <br />
                  <b className="teacher-name">{f.name}</b>
                  <br />
                  <span className=" teacher-edu">
                    <b>{f.designation}</b>
                  </span>
                  <br />
                  <span className=" teacher-edu">
                    {f.qualification}
                    <br />
                    {f.joindate}
                  </span>
                </div>
              )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Faculty;