import React, { useEffect, useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Form, Col, Row, Table, CardBody, Button, Card } from "react-bootstrap";
import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    course_id: yup.string().required("Fill value"),
    part_id: yup.string().required("Fill value"),
    acd_year: yup.string().required("Fill value"),

});

const StudentLogin = () => {

    const { register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [academicList, setAcademicList] = useState();
    const [coursecList, setCoursecList] = useState();
    const [samesterList, setSamesterList] = useState([]);
    // const [studentList, setStudentList] = useState();
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const getAcademicYear = async () => {
        await fetch(setting.api + "api/getAcademicYear", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setAcademicList(u);

            });
    }

    const getAllCourse = async () => {
        await fetch(setting.api + "api/getAllCourse", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setCoursecList(u.data);

            });
    }

    const getYearSem = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "api/getYearSem?brc_id=" + id, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setSamesterList(u.data);

            });
    }

    const getStudentRecord = async (data) => {
        let sr = {
            "course_id": data.course_id,
            "part_id": data.part_id,
            "acd_year": data.acd_year
        }
        await fetch(setting.remote_api + "api/getStudentRecord", {
            body: JSON.stringify(sr),
            method: "post",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                setList(u.data);

            });
    }


    useEffect(() => {

        getAcademicYear();
        getAllCourse();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);

    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }
    console.log(academicList);

    return (
        <>
            <Header />

            <div className="header-form">
                <div className="bg-lay">
                    <div className="cross">
                        <i className="fas fa-times"></i>
                    </div>
                </div>
                <form className="form-container">
                    <input type="text" placeholder="Input Your Search" name="name" />
                    <button type="submit">Search</button>
                </form>
            </div>

            <section className="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Student Login</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                Login
                            </li>

                        </ul>
                    </div>
                </div>
            </section>

            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="feedback-title">Login</h3>
                        <br />
                        <Form onSubmit={handleSubmit()}>


                            {/* <Form.Group className="mb-3 ">
                                <Form.Select aria-label="Select Course" {...register("course")}  >

                                    <option> Select Course</option>
                                    {list && list.map((a, i) =>
                                        <option value={a.id}>{a.course}</option>
                                    )}
                                    {errors.course && <p>{errors.course.message}</p>}

                                </Form.Select>
                            </Form.Group> */}

                            <Form.Group className="mb-3 " >
                                <Form.Control type="text" placeholder="Email" className="text"
                                    {...register("email")}
                                />
                                {errors.email && <p>{errors.email.message}</p>}
                            </Form.Group>

                            <Form.Group className="mb-3 " >
                                <Form.Control type="password" placeholder="Password" className="text"
                                    {...register("password")}
                                />
                                {errors.password && <p>{errors.password.message}</p>}
                            </Form.Group>


                            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                    {...register("message")}
                                />
                                {errors.message && <p>{errors.message.message}</p>}
                            </Form.Group> */}

                            <div className="form-group">
                                <button type="submit" className="d-block custom-button">
                                    <span>Submit Now</span>
                                </button>
                            </div>

                        </Form>
                    </div>
                </div>

            </div>


            <Footer />
        </>
    );
}

export default StudentLogin;