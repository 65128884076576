import React, { useState, useEffect } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Table } from "react-bootstrap";
import setting from "../setting.json";

const Circular = () => {

    const [list, setList] = useState([]);

    const getNcteOrder = async (data) => {

        await fetch(setting.api + "api/getNcteOrder")
            .then(response => response.json())
            .then(u => {
                setList(u.data);

            });
    }

    useEffect(() => {
        getNcteOrder();
    }, []);

    return (
        <>
            <Header />

            <section className="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Circular</h1>
                        <ul className="breadcrumb">
                            <li>
                                <a href="/">Home</a>
                            </li>

                            <li>Circular</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="login-section padding-top padding-bottom">
                <div className="container">

                    <h3 className="feedback-title">Details</h3>
                    <div className="cart-top mb-widget" style={{ marginTop: 10 }}>
                        <Table bordered hover variant="light">
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Title</th>
                                    <th>Download</th>

                                </tr>
                            </thead>
                            <tbody id="result">
                                {list && list.map((a, i) =>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{a.title}</td>
                                        <td><a href={a.file} target="_blank">View</a></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div >

            <Footer />
        </>
    );
}

export default Circular;