
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Contact from './pages/Contact';
import Circular from './pages/Circular';
import AnnualReport from './pages/AnnualReport';
import Disciplinary from './pages/Disciplinary';
import Index from './pages/Index';
import Academic from './pages/Academic';
import Faculty from './pages/Faculty';
import AntiRagging from './pages/AntiRagging';
import Committee from './pages/Committee_SC_ST';
import PlacementCell from './pages/PlacementCell';
import GenderHarassment from './pages/GenderHarassment';
import SamarthPortal from './pages/SamarthPortal';
import Timetable from './pages/Timetable';
import StudentRecord from './pages/StudentRecord';
import StudentLogin from './pages/StudentLogin';
import BiometricAttendance from './pages/BiometricAttendance';
import StudentComplaints from './pages/StudentCompalints';
import Feedback from './pages/Feedback';
import NewsNotification from './pages/News-Notification';
import Gallery from './pages/Gallery';
import Examination from './pages/Examination';
import EnquiryForm from './pages/EnquiryForm';
import StudentDashboard from './pages/StudentDashboard';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/enquiryform",
    element: <EnquiryForm />,
  },
  {
    path: "/examination",
    element: <Examination />,
  },
  {
    path: "/circular",
    element: <Circular />,
  },
  {
    path: "/annualReport",
    element: <AnnualReport />,
  },
  {
    path: "/disciplinary",
    element: <Disciplinary />,
  },
  {
    path: "/academic",
    element: <Academic />,
  },
  {
    path: "/faculty",
    element: <Faculty />,
  },
  {
    path: "/antiRagging",
    element: <AntiRagging />,
  },
  {
    path: "/committee_sc_st",
    element: <Committee />,
  },
  {
    path: "/placementCell",
    element: <PlacementCell />,
  },
  {
    path: "/genderHarassment",
    element: <GenderHarassment />,
  },
  {
    path: "/samarthPortal",
    element: <SamarthPortal />,
  },
  {
    path: "/timetable",
    element: <Timetable />,
  },
  {
    path: "/studentRecord",
    element: <StudentRecord />,
  },
  {
    path: "/studentLogin",
    element: <StudentLogin />,
  },
  {
    path: "/studentDashboard",
    element: <StudentDashboard />,
  },
  {
    path: "/attendance",
    element: <BiometricAttendance />,
  },
  {
    path: "/studentComplaints",
    element: <StudentComplaints />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
  },
  {
    path: "/notification",
    element: <NewsNotification />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
]);
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
