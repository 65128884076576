import React, { useEffect, useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Form, Col, Row, Table, CardBody, Button, Card } from "react-bootstrap";
import setting from "../setting.json";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    course_id: yup.string().required("Fill value"),
    base_id: yup.string().required("Fill value"),
    acd_year: yup.string().required("Fill value"),
});

const Examination = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [academicList, setAcademicList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [semesterList, setSemesterList] = useState([]);
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(20);

    // Calculate page count dynamically
    const pageCount = Math.ceil(list.length / pageSize);

    useEffect(() => {
        getAcademicYear();
        getAllCourse();
    }, []);

    const getAcademicYear = async () => {
        try {
            const response = await fetch(`${setting.api}api/getAcademicYear`, { method: "GET", mode: "cors" });
            const result = await response.json();
            setAcademicList(result);
        } catch (error) {
            console.error("Error fetching academic years:", error);
        }
    };

    const getAllCourse = async () => {
        try {
            const response = await fetch(`${setting.api}api/getAllCourse`, { method: "GET", mode: "cors" });
            const result = await response.json();
            setCourseList(result.data || []);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    const getYearSem = async (e) => {
        const id = e.target.value;
        try {
            const response = await fetch(`${setting.api}api/getYearSem?brc_id=${id}`, { method: "GET", mode: "cors" });
            const result = await response.json();
            setSemesterList(result.data || []);
        } catch (error) {
            console.error("Error fetching semesters:", error);
        }
    };

    const searchExaminationRecord = async (data) => {
        let sr = {
            course_id: data.course_id,
            part_id: data.base_id,
            acd_year: data.acd_year
        };

        try {
            const response = await fetch(`${setting.api}api/searchExaminationRecord`, {
                method: "POST",
                mode: "cors",
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(sr),
            });
            const result = await response.json();
            setList(result.data);
            setCurrentPage(1);
        } catch (error) {
            console.error("Error searching examination record:", error);
        }
    };

    // Update displayed data based on pagination
    useEffect(() => {
        const start = (currentPage - 1) * pageSize;
        const end = start + pageSize;
        setData(list.slice(start, end));
    }, [currentPage, list]);

    return (
        <>
            <Header />
            <section className="page-header bg_img" style={{ backgroundImage: "URL(./images/banner2.jpg)", height: "30lvh" }}>
                <div className="container">
                    <div className="page-header-content">
                        <h1 className="title">Examination</h1>
                        <ul className="breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Examination</li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="shop-cart padding-top padding-bottom">
                <div className="container">
                    <div className="cart-checkout-box mb-widget">
                        <div className="card-body">
                            <Card>
                                <CardBody>
                                    <Form onSubmit={handleSubmit(searchExaminationRecord)}>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Select {...register("acd_year")}>
                                                        <option>Academic Year</option>
                                                        {academicList.map(a => (
                                                            <option key={a.id} value={a.id}>{a.start_year}-{a.end_year}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.acd_year && <p>{errors.acd_year.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Select {...register("course_id")} onChange={getYearSem}>
                                                        <option>Course</option>
                                                        {courseList.map(a => (
                                                            <option key={a.id} value={a.id}>{a.course}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.course_id && <p>{errors.course_id.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Select {...register("base_id")}>
                                                        <option>Semester</option>
                                                        {semesterList.map(a => (
                                                            <option key={a.base_id} value={a.base_id}>{a.base_name}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {errors.base_id && <p>{errors.base_id.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Button type="submit" variant="secondary">Search</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <div className="cart-top mb-widget">
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Name</th>
                                    <th>Admission No</th>
                                    <th>Total Marks</th>
                                    <th>Obtained Marks</th>
                                    <th>Result</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((a, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{a.stu_fname} {a.stu_mname} {a.stu_lname}</td>
                                        <td>{a.admno}</td>
                                        <td>{a.total_marks}</td>
                                        <td>{a.marks_obtained}</td>
                                        <td><b>{a.status.toUpperCase()}</b></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <div className='page'>
                            <Button variant='secondary' size="sm" onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>{`<<`}</Button>{' '}
                            <Button variant='secondary' size="sm" onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>{`<`}</Button>
                            <span> Page {currentPage} of {pageCount} </span>
                            <Button variant='secondary' size="sm" onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))} disabled={currentPage >= pageCount}>{`>`}</Button>{' '}
                            <Button variant='secondary' size="sm" onClick={() => setCurrentPage(pageCount)} disabled={currentPage >= pageCount}>{`>>`}</Button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Examination;
