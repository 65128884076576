import React, { useEffect, useState } from 'react'
import Header from "../component/Header";
import Footer from '../component/Footer';
import setting from "../setting.json";
import { Row, Card, CardBody, CardHeader, Button } from 'react-bootstrap';

function NewsNotification() {

    const [news, setNews] = useState('');

    const getAllNews = async () => {
        await fetch(setting.api + "api/getAllNews", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setNews(u.data);

            });
    }

    useEffect(() => {
        getAllNews();
    }, []);

    return (
        <div>
            <Header />
            <div class="header-form">
                <div class="bg-lay">
                    <div class="cross">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <form class="form-container">
                    <input type="text" placeholder="Input Your Search" name="name" />
                    <button type="submit">Search</button>
                </form>
            </div>

            <section class="page-header bg_img"
                style={{
                    backgroundImage: "URL(./images/banner2.jpg)",
                    height: "30lvh",
                }}
            >
                <div class="container">
                    <div class="page-header-content">
                        <h1 class="title text-light">News & Notification</h1>
                        <ul class="breadcrumb">
                            <li>
                                <a className='text-light' href="index-2.html">Home</a>
                            </li>
                            <li className='text-light'> News & Notificaation </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section class="faqs-single-section padding-top padding-bottom">
                <div class="container">
                    <h3><img src="./images/newspaper.png" alt="" height={40} className='px-2' />All News & Notifications <img src="./images/newspaper.png" alt="" height={40} className='px-2' /></h3>
                    <br />
                    <br />
                    <div >
                        <Card
                            style={{ border: "solid 2px #2f2f30" }}
                        >
                            <Card.Body>
                                <div class="widget-body">
                                    {news && news.map((n) =>
                                        <p className="text-start mt-3" style={{ borderBottom: "solid 1px" }}><i className="fa fa-solid fa-arrow-right"></i><b>{n.description}</b>

                                            <a href={n.file} target="_blank"><Button variant="danger" size="sm" style={{ padding: "unset", marginLeft: "4px" }}>new</Button></a>
                                        </p>
                                    )}
                                </div>

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default NewsNotification